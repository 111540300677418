export function useFormModel<T>(model: Ref<T>, initialModelState?: MaybeRefOrGetter<T>) {
  const initialState = ref<T>(useCloneDeep(initialModelState ? toValue(initialModelState) : model.value)) as Ref<T>

  function refresh() {
    initialState.value = useCloneDeep(model.value)
  }

  function reset() {
    model.value = useCloneDeep(initialState.value)
  }

  const edited = computed(() => {
    return !isEqual(initialState.value, model.value)
  })

  return { edited, refresh, reset }
}
